<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 客户名称 -->
      <FormItem>
        <span>标题名称：</span>
        <Input clearable v-model="queryFrom.title" class="iviewIptWidth250"></Input>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="Model2 = true">新增</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :pages="pages" :isLoad="isLoad" :loading="isLoad" totalText="条记录" :total="total" @change-page="changePage"> </Table>
    <!-- 通知公告弹窗 -->
    <Modal v-model="Model1" title="通知公告" width="1000" :closable="false" :mask-closable="false">
      <div class="noticeTitle">
        <span class="sp1">标题：</span>
        <span class="sp2">{{ NoticeInfo.title }}</span>
      </div>
      <div class="noticeTitle">
        <span class="sp1">内容：</span>
        <div class="ql-editor infoContent" id="notice"></div>
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="Model1 = false"> 确定 </span>
      </div>
    </Modal>
    <!-- 通知公告弹窗 -->
    <Modal v-model="Model2" title="新增通知公告" width="1000" :closable="false" :mask-closable="false">
      <div class="noticeTitle flexS">
        <span class="sp1">标题：</span>
        <Input maxlength="100" v-model="step.title" style="width: 100%; flex: 1;"></Input>
      </div>
      <div class="noticeTitle">
        <span class="sp1">内容：</span>
        <div class="infoContent">
          <quill-editor class="editor" v-model="step.content" :options="editorOption" ref="myQuillEditor"> </quill-editor>
        </div>
      </div>
      <div slot="footer" class="marginTop">
        <span class="pageBtn finger btnReset" @click="cancellNotice"> 取消 </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="newConfirm"> 确定 </span>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="Model3" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否删除此通知公告？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="deleteNotice">确定</span>
          <span class="pageBtn finger btnCancle" @click="Model3 = false">取消</span>
        </div>
      </div>
    </Modal>

    <Upload id="iviewUp" :show-upload-list="false" :on-success="handleSuccessQuill" :format="['jpg', 'jpeg', 'png', 'gif']" :headers="header" name="richTextAccessory" :max-size="2048" :action="url" style="display: none;" :on-format-error="onFormatError">
      <Button icon="ios-cloud-upload-outline"></Button>
    </Upload>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Table from '@/components/table'
import http from '@/service/baseUrl.js'
export default {
  components: {
    Table,
    quillEditor,
  },
  data() {
    return {
      Model1: false,
      Model2: false,
      Model3: false,
      announcement_detail_id: '',
      step: {
        title: '',
        content: '',
      },
      NoticeInfo: {},
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              // ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{'direction': 'rtl'}],                         // 文本方向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ font: [] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'], // 链接、图片、视频
            ],
            handlers: {
              image: function (value) {
                // 对图片进行改造，默认是通过base64 ,现通过iview 去传。
                if (value) {
                  document.querySelector('#iviewUp input').click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
        },
      },
      queryFrom: {
        title: '',
        page: 1,
        rows: 10,
        content: '',
      },
      listData: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 90,
        },
        {
          title: '标题名',
          key: 'title',
          align: 'center',
        },
        {
          title: '创建人',
          key: 'user_name',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'create_time',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return (
              h,
              'div',
              [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                      marginRight: '40px',
                    },
                    on: {
                      click: () => {
                        // this.Model1 = true
                        this.looknotic(param.row)
                      },
                    },
                  },
                  '查看'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.Model3 = true
                        this.announcement_detail_id = param.row.announcement_detail_id
                      },
                    },
                  },
                  '删除'
                ),
              ]
            )
          },
        },
      ],
      pages: {
        page: 1,
        rows: 10,
      },
      isLoad: true,
      total: 0,
      url: '',
      header: '',
    }
  },
  created() {
    this.query()
    this.url = http.http + '/upload_file'
    console.log(http)
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem('adminohoqicbi=='),
    }
  },
  methods: {
    // 关闭弹窗
    cancellNotice() {
      this.Model2 = false
      this.step.title = ''
      this.step.content = ''
    },
    // 删除通知公告
    deleteNotice() {
      this.$http.DeleteAll(this.$api.AnnouncementManage, { announcement_detail_id: this.announcement_detail_id }, true).then(res => {
        this.Model3 = false
        this.$Message.success('删除成功')
        this.query()
      })
    },
    // 新增
    newConfirm() {
      if (!this.step.title) {
        this.$Message.warning('请填文件标题')
        return
      }
      if (!this.step.content) {
        this.$Message.warning('请输入内容')
        return
      }
      let obj = {
        title: this.step.title,
        content: this.step.content,
      }
      this.$http.post(this.$api.AnnouncementManage, obj, true).then(res => {
        this.$Message.success('上传成功')
        // 回到初始状态1
        this.step = this.$options.data().step
        this.query()
        this.Model2 = false
      })
    },
    // 上传格式验证
    onFormatError(file, fileList) {
      console.log(file, fileList)
      this.$Message.error('只允许上传gif,jpg,jpeg,bmp,png格式的图片')
    },
    handleSuccessQuill(res) {
      let url = ''
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill
      // 如果上传成功
      // 获取光标所在位置
      let length = quill.getSelection().index
      url = res.data.file_url
      quill.insertEmbed(length, 'image', url)
      // 调整光标到最后
      quill.setSelection(length + 1)
    },
    //   分页
    changePage(e) {
      this.pages.page = e
      this.query()
    },
    //   查询
    query() {
      let obj = {
        title: this.queryFrom.title,
        page: this.pages.page,
        rows: this.pages.rows,
      }
      this.isLoad = true
      this.$http.get(this.$api.AnnouncementManage, obj, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.listData.forEach(item => {
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
        })
      })
    },
    //   查看通知
    looknotic(item) {
      this.$http.get(this.$api.AnnouncementDetail, { announcement_detail_id: item.announcement_detail_id }, true).then(res => {
        console.log(res)
        this.Model1 = true
        this.NoticeInfo = res.data
        this.NoticeInfo.content = this.NoticeInfo.content.replace(/\\/gi, '')
        let notice = document.getElementById('notice')
        notice.innerHTML = this.NoticeInfo.content
      })
    },
  },
}
</script>

<style lang="less" scoped>
.marginLeft20 {
  margin-left: 20px;
}
.noticeTitle {
  width: 100%;
  margin: 15px 0px;
  //    display: flex;
  //    align-items: center;
  height: auto;
  .sp1 {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
  .sp2 {
    font-size: 16px;
    color: #333333;
  }
  .infoContent {
    width: 100%;
    height: 450px;
    overflow-y: auto;
    // p {
    //   font-size: 16px;
    //   color: #333333;
    //   line-height: 40px;
    //   text-indent: 32px;
    // }
  }
}
.marginTop {
  margin-top: 50px;
}
.flexS {
  display: flex;
  align-items: center;
}
.editor {
  line-height: normal !important;
  height: 350px;
}
/deep/ .ql-editor {
  color: #333333 !important;
}
/deep/ .ql-editor p {
  font-size: 16px !important;
}
/deep/ .ql-snow .ql-tooltip[data-mode='link']::before {
  content: '请输入链接地址:';
}
/deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}

/deep/ .ql-snow .ql-tooltip[data-mode='video']::before {
  content: '请输入视频地址:';
  display: none;
}

// /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item::before {
//   content: '14px';
// }
// /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
//   content: '10px';
// }
// /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
//   content: '18px';
// }
// /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
//   content: '32px';
// }

/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6';
}

/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: '衬线字体';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: '等宽字体';
}
/deep/ .ql-video {
  display: none !important;
}
</style>
